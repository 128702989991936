<template>
  <v-container fluid>
    <v-card flat :loading="data_loading">
      <v-tabs v-model="tabs" class="my-tabs" @change="update_list">
        <v-tab> Quantité </v-tab>
        <v-tab> Valeur </v-tab>
      </v-tabs>

      <v-row>
        <v-col cols="12" sm="6" md="12">
          <listitemspages
            :selitem="selitem"
            :qSelect="Qselect"
            :qSelectall="Qselectall"
            :PageResponse="'detailscmds'"
            :AllResponse="'alldetailscmds'"
            :title="'Suivi des commandes'"
            :headers="headers"
            :field="'CREATED_AT'"
            :filename="'Suivi Commandes'"
            :sheetname="'Suivi Commandes'"
            @rowselect="ProductChange"
            @hrf="openList"
            :TypeScope="tiertype"
            :showedit="false"
            :Kind="valider_cmd ? 1 : 0"
            :key="kp"
            :ipg="15"
            :Add="false"
            :del="false"
            :btn1="
              (tiertype == 1 || tiertype == 3) &&
              ($store.state.auth.includes('02036') || $store.state.isadmin)
                ? 'Faisabilite'
                : ''
            "
            :btn1_disable="product.qte == product.qte_lanc || !product.bom_ok"
            @click1="faisabilite"
            :btn2="solder_cmd ? 'Solder' : ''"
            :btn2_load="solde_list_load"
            @click2="show_solde_list"
            @col_btn1_click="solder_ligne"
            @multiple_click="solder_list2"
            :groupby="[0, 2, 4, 5]"
            :search_elm="filtre"
            :multiple="true"
            :multiple_icon="'mdi-close-circle-multiple-outline'"
          >
          </listitemspages>
        </v-col>
      </v-row>
      <formlist
        :title="form_title"
        :subtitle="subtitle"
        :Qdelete="qdelete_order"
        :list="items_list"
        :showform="!isListClose"
        :listheaders="items_headers"
        :key="fl"
        @close="closeList"
        :Total="true"
        :master="true"
        :add="add"
        :Update="true"
        :del="del"
        :multiple="multiple"
        :del_disable="order.qte_liv > 0"
        :width="w"
        :showedit="false"
        :btn_save="btn_save"
        :btn_save_caption="'Clotûrer'"
        :btn_save_dis="false"
        @open="openForm"
        @delete="refresh"
        @rowselect="ItemChange"
        :laoding="loading"
        :kid="kid"
        :message="message"
        :type_alert="type_alert"
        :alert="alert"
        @input="alert = false"
        @save="solder_list"
      >
      </formlist>
      <orderform
        :item="order"
        :key="korder"
        :showForm="!isOrderClosed"
        @close="closeOrderForm"
        :editer="lancer"
        :valider="valider"
        :solder="solder"
        @refresh="refresh"
        @add="refresh_list"
        :produit="product"
        :units="units"
        :templates="templates"
        :attributes="attributes"
        :boms_list="boms"
        :create="true"
        :code_app="3"
      >
      </orderform>
      <faisabilite
        :showForm="!isFaisabiliteClosed"
        :product="product"
        :produit_id="parseInt(product.produit_id)"
        :key="kfais"
        :units="units"
        :templates="templates"
        :attributes="attributes"
        @close="isFaisabiliteClosed = true"
      >
      </faisabilite>
      <v-snackbar
        v-model="snackbar"
        :timeout="snackbar_timeout"
        top
        :color="snackbar_color"
      >
        {{ snackbar_text }}
      </v-snackbar>
      <confirmdialog ref="confirm" :is_motif="is_motif" @motif="get_motif" />
    </v-card>
  </v-container>
</template>

<script>
import DETAILSCMDS from "../graphql/Commande/DETAILSCMD.gql";
import DETAILSCMD_FIND from "../graphql/Commande/DETAILSCMD_FIND.gql";
import ALLDETAILSCMDS from "../graphql/Commande/ALLDETAILSCMDS.gql";
import DELETE_ORDER from "../graphql/Production/DELETE_ORDER.gql";
//import ALLORDERS from "../graphql/Production/ALLORDERS.gql";
import UPDATE_PRODUIT from "../graphql/Commande/UPDATE_PRODUIT.gql";
import SOLDER_LIGNES_CMD from "../graphql/Commande/SOLDER_LIGNES_CMD.gql";
import DATA from "../graphql/Commande/DATA_SUIVI.gql";
import PRODUCT_LIST from "../graphql/Product/PRODUCT_LIST.gql";
import ALLCMDS from "../graphql/Commande/ALLCMDS.gql";
import ALLPAYE from "../graphql/Facture/ALLPAYE.gql";
//import BESOIN_PRODUCT from "../graphql/Production/BESOIN_PRODUCT.gql";
import { arrivage, version } from "print/data.js";
export default {
  components: {
    listitemspages: () => import("../components/ListItemsPages.vue"),
    formlist: () => import("../components/FormList.vue"),
    faisabilite: () => import("../components/Faisabilite.vue"),
    orderform: () => import("../components/OrderForm.vue"),
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
  },
  props: {
    tiertype: Number,
  },
  data: () => ({
    filtre: "",
    valider: false,
    valider_cmd: false,
    data_loading: false,
    solder: false,
    solder_cmd: false,
    message: "",
    alert: false,
    is_motif: false,
    motif: "",
    type_alert: "info",
    subtitle: "",
    kid: "id",
    w: "1400px",
    form_title: "Commandes Internes",
    isListClose: true,
    isOrderClosed: true,
    isFaisabiliteClosed: true,
    selitem: {},
    add: false,
    del: false,
    kp: 100,
    korder: 200,
    kfais: 300,
    fl: 400,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    multiple: false,
    btn_save: false,
    units: [],
    selected_list: [],
    boms: [],
    headers: [
      {
        text: "N° Cmd",
        value: "nocmd",
        enum: "NOCMD",
        selected: true,
        warned: true,
        width: "8%",
        index: 0,
      },
      {
        text: "Unite",
        value: "site_name",
        hiden: true,
        selected: true,
        index: 1,
      },
      {
        text: "Date",
        value: "date_cmd",
        enum: "DATE_CMD",
        slot: "date",
        selected: true,
        expire: true,
        width: "7%",
        index: 2,
      },
      {
        text: "Ref. Prod",
        align: "text-left",
        value: "ref_product",
        enum: "REF_PRODUCT",
        filter: true,
        selected: true,
        index: 3,
      },
      {
        text: "Tier",
        align: "text-left",
        value: "tier_name",
        enum: "TIER_NAME",
        filter: true,
        selected: true,
        index: 4,
      },
      {
        text: "Produit",
        value: "code_label",
        enum: "CODE_LABEL",
        warned: true,
        selected: true,
        index: 5,
      },
      {
        text: "Avant le",
        value: "date_limite",
        enum: "DATE_LIMITE",
        slot: "date",
        expire: true,
        selected: false,
        index: 6,
      },
      {
        text: "Délai(j)",
        value: "j_reste",
        enum: "J_RESTE",
        slot: "cur22",
        dec: 0,
        expire: true,
        selected: false,
        align: "center",
        index: 7,
      },
      {
        text: "Prix",
        value: "pu",
        selected: false,
        enum: "PU",
        slot: "cur",
        align: "end",
        width: "6%",
        index: 8,
      },
      {
        text: "Devise",
        value: "devise",
        enum: "DEVISE",
        selected: false,
        width: "4%",
        index: 9,
      },
      {
        text: "Unité",
        value: "unit",
        enum: "UNIT",
        selected: true,
        width: "4%",
        index: 10,
      },
      {
        text: "Cmd",
        value: "qte",
        enum: "QTE",
        selected: true,
        slot: "cur",
        align: "end",
        width: "8%",
        index: 11,
      },
      {
        text: "Lancé",
        value: "qte_lanc",
        enum: "QTE_LANC",
        slot: "href3",
        selected: true,
        align: "end",
        hiden: true,
        width: "7%",
        index: 12,
      },

      {
        text: "Encours",
        value: "qte_encours",
        enum: "QTE_ENCOURS",
        slot: "cur",
        selected: false,
        align: "end",
        hiden: true,
        width: "7%",
        index: 13,
      },
      {
        text: "Fab",
        value: "qte_fab",
        enum: "QTE_FAB",
        selected: false,
        slot: "cur",
        align: "end",
        hiden: true,
        width: "7%",
        index: 14,
      },
      {
        text: "Arrivages",
        value: "qte_import",
        enum: "QTE_IMPORT",
        selected: true,
        slot: "href3",
        align: "end",
        total: "qte_import",
        hiden: true,
        width: "7%",
        index: 15,
      },
      {
        text: "Rcpt",
        value: "qte_rcpt",
        enum: "QTE_RCPT",
        selected: true,
        slot: "href3",
        align: "end",
        total: "qte_rcpt",
        hiden: true,
        width: "7%",
        index: 16,
      },
      {
        text: "% Rcpt/Cmd",
        value: "prc_rcpt",
        enum: "PRC_RCPT",
        selected: true,
        slot: "progress",
        align: "center",
        index: 17,
      },
      {
        text: "Solde",
        value: "rest_import",
        enum: "REST_IMPORT",
        selected: true,
        slot: "cur",
        hiden: true,
        align: "end",
        width: "8%",
        index: 18,
      },
      {
        text: "Rcpt",
        value: "qte_liv",
        enum: "QTE_LIV",
        selected: true,
        slot: "href3",
        align: "end",
        width: "7%",
        index: 19,
      },

      {
        text: "% Rcpt/Cmd",
        value: "prc_liv",
        enum: "PRC_LIV",
        slot: "progress",
        cloture: true,
        align: "center",
        selected: true,
        index: 20,
      },
      {
        text: "Ret.",
        value: "qte_ret",
        enum: "QTE_RET",
        selected: false,
        slot: "href3",
        align: "end",
        width: "7%",
        index: 21,
      },
      {
        text: "a liv",
        value: "rest_liv",
        enum: "REST_LIV",
        selected: true,
        slot: "cur",
        hiden: true,
        align: "end",
        width: "8%",
        index: 22,
      },

      {
        text: "Statut",
        value: "statut_name",
        enum: "STATUT_NAME",
        selected: false,
        index: 23,
      },

      {
        text: "Motif",
        value: "motif",
        enum: "MOTIF",
        selected: false,
        index: 24,
      },
      {
        text: "",
        slot: "col_btn1",
        selected: true,
        icon: "mdi-close-circle-multiple-outline",
        tooltip: "Solder la ligne de commande",
        column: "cloture",
        valeur: false,
        sortable: false,
        hiden: true,
        width: "1%",
        index: 25,
      },
      {
        text: "",
        slot: "col_btn1",
        selected: true,
        icon: "mdi-restore",
        tooltip: "Restorer la ligne de commande",
        column: "cloture",
        valeur: true,
        sortable: false,
        hiden: true,
        width: "1%",
        index: 26,
      },
      {
        text: "Int/Ext",
        value: "interne",
        enum: "INTERNE",
        selected: false,
        index: 27,
      },
      {
        text: "Ref. Cmd",
        align: "text-left",
        value: "cmd_ref",
        enum: "CMD_REF",
        filter: true,
        selected: false,
        index: 28,
      },
    ],
    solde_headers: [
      {
        text: "N°",
        value: "no",
        selected: true,
      },
      {
        text: "N° Cmd",
        value: "nocmd",
        selected: true,
      },
      {
        text: "Date",
        value: "date_cmd",
        slot: "date",
        selected: true,
        expire: true,
      },
      {
        text: "Ref. Prod",
        value: "ref_product",
        selected: true,
      },
      {
        text: "Tier",
        value: "tier_name",
        selected: true,
      },
      {
        text: "Produit",
        value: "code_label",
        selected: true,
      },
      {
        text: "Prix",
        value: "pu",
        selected: false,
        slot: "cur",
        align: "end",
      },
      {
        text: "Unité",
        value: "unit",
        selected: true,
      },
      {
        text: "Cmd",
        value: "qte",
        selected: true,
        slot: "cur",
        align: "end",
        width: "7%",
      },

      {
        text: "Rcpt",
        value: "qte_rcpt",
        selected: true,
        slot: "cur",
        align: "end",
        width: "7%",
      },
      {
        text: "% Rcpt/Cmd",
        value: "prc_rcpt",
        selected: true,
        slot: "progress",
        align: "center",
      },

      {
        text: "Liv.",
        value: "qte_liv",
        selected: true,
        slot: "cur",
        align: "end",
        width: "7%",
      },

      {
        text: "% Liv/Cmd",
        value: "prc_liv",
        slot: "progress",
        cloture: true,
        align: "center",
        selected: true,
      },

      {
        text: "Statut",
        value: "statut_name",
        enum: "STATUT_NAME",
        selected: false,
      },

      {
        text: "Ref. Cmd",
        align: "text-left",
        value: "cmd_ref",
        enum: "CMD_REF",
        selected: false,
      },
    ],
    avenants_headers: [
      {
        text: "No",
        value: "no",
        selected: true,
      },
      {
        text: "Type",
        value: "contrat_type",
        selected: true,
      },
      {
        text: "Ref.",
        value: "nocmd",
        selected: true,
      },
      {
        text: "Date ",
        value: "sign_date",
        slot: "date",
        selected: true,
      },

      {
        text: "Qte",
        value: "qte",
        selected: true,
        slot: "cur",
        align: "end",
      },
      {
        text: "Prix",
        value: "pu",
        selected: true,
        slot: "cur",
        align: "end",
      },
    ],
    orders_headers: [
      {
        text: "No",
        value: "no",
        selected: true,
      },
      {
        text: "Code",
        value: "no_order",
        slot: "href",
        selected: true,
      },
      {
        text: "Atelier",
        value: "site_name",
        selected: true,
        hiden: true,
      },
      {
        text: "Date of",
        value: "date_order",
        slot: "date",
        enum: "DATE_ORDER",
        selected: true,
      },
      {
        text: "Avant le",
        value: "date_limite",
        slot: "date",
        enum: "date_limite",
        expire: true,
        selected: true,
      },
      {
        text: "Qte Lanc",
        value: "qte_lanc",
        selected: true,
        slot: "cur",
        align: "end",
        total: "qte_lanc",
      },
      {
        text: "Encours",
        value: "qte_encours",
        slot: "cur",
        selected: true,
        align: "end",
        hiden: true,
        total: "qte_encours",
      },
      {
        text: "Qte Fab",
        value: "qte_fab",
        selected: true,
        slot: "cur",
        align: "end",
        total: "qte_fab",
      },
      {
        text: "Reste a Fab.",
        value: "qte_rest",
        selected: true,
        slot: "cur",
        align: "end",
        total: "qte_rest",
      },
      {
        text: "% Fab",
        value: "prc_fab",
        selected: true,
        slot: "cur",
        align: "end",
      },
      {
        text: "Qte Rcpt.",
        value: "qte_rcpt",
        selected: true,
        slot: "cur",
        align: "end",
        total: "qte_rcpt",
      },
      {
        text: "% Rcpt.",
        value: "prc_receipt",
        slot: "cur",
        align: "end",
        cloture: true,
        selected: true,
      },
      {
        text: "Statut",
        value: "statut_name",
        selected: true,
      },
    ],
    rcpts_headers: [
      {
        text: "No",
        value: "no",
        selected: true,
      },
      {
        text: "Ref.",
        value: "nobon",
        selected: true,
      },
      {
        text: "Date Rcpt",
        value: "opr_date",
        slot: "date",
        selected: true,
      },
      {
        text: "Code",
        value: "code",
        hiden: true,
        selected: true,
      },
      {
        text: "Article",
        value: "label",
        hiden: true,
        selected: true,
      },
      {
        text: "Qte Rcpt",
        value: "qte",
        selected: true,
        slot: "cur",
        align: "end",
        total: "qte",
      },
      {
        text: "Nombre",
        value: "coliscount",
        selected: true,
        slot: "cur",
        align: "end",
        hiden: true,
        total: "coliscount",
        dec: 0,
      },
      {
        text: "Depot",
        value: "depot_name",
        selected: true,
      },
    ],
    imports_headers: [
      {
        text: "No",
        value: "no",
        selected: true,
      },
      {
        text: "N° Dossier",
        value: "nodossier",
        selected: true,
      },
      {
        text: "PU",
        value: "pu",
        selected: true,
        slot: "cur",
        align: "end",
        dec: 2,
      },
      {
        text: "Qte",
        value: "qte",
        selected: true,
        slot: "cur",
        align: "end",
        total: "qte",
        dec: 2,
      },
      {
        text: "Montant",
        value: "montant",
        selected: true,
        slot: "cur",
        align: "end",
        total: "montant",
        dec: 2,
      },

      {
        text: "Date Embarquement",
        value: "date_embar",
        slot: "date",
        selected: true,
      },

      {
        text: "Date Débarquement",
        value: "date_debarq",
        slot: "date",
        selected: true,
      },
      {
        text: "Lieu Débarquement",
        value: "port",
        selected: true,
      },
    ],
    livs_headers: [
      {
        text: "No",
        value: "no",
        selected: true,
      },
      {
        text: "Ref.",
        value: "nobon",
        selected: true,
      },
      {
        text: "Date Livr.",
        value: "opr_date",
        slot: "date",
        selected: true,
      },
      {
        text: "PU",
        value: "pu",
        selected: true,
        slot: "cur",
        align: "end",
        dec: 2,
      },
      {
        text: "Qte Livrée",
        value: "qte",
        selected: true,
        slot: "cur",
        align: "end",
        total: "qte",
        dec: 2,
      },
      {
        text: "Nombre",
        value: "coliscount",
        selected: true,
        slot: "cur",
        align: "end",
        hiden: true,
        total: "coliscount",
        dec: 0,
      },
      {
        text: "Depot",
        value: "depot_name",
        selected: true,
      },
    ],
    rets_headers: [
      {
        text: "No",
        value: "no",
        selected: true,
      },
      {
        text: "Ref.",
        value: "nobon",
        selected: true,
      },
      {
        text: "Date Retour",
        value: "opr_date",
        slot: "date",
        selected: true,
      },

      {
        text: "Qte Retour.",
        value: "qte",
        selected: true,
        slot: "cur",
        align: "end",
        total: "qte",
      },
      {
        text: "Nombre",
        value: "coliscount",
        selected: true,
        slot: "cur",
        align: "end",
        hiden: true,
        total: "coliscount",
        dec: 0,
      },
      {
        text: "Depot",
        value: "depot_name",
        selected: true,
      },
    ],
    colisheaders: [
      {
        text: "No",
        value: "no",
        selected: true,
        totlib: true,
      },

      {
        text: "Quantité",
        value: "qte_rest",
        total: "qte_rest",
        slot: "cur",
        align: "end",
        selected: true,
        dec: 2,
      },

      {
        text: "Comment",
        value: "comment",
        selected: true,
        edit: true,
      },
    ],
    besoinsheaders: [
      {
        text: "No",
        value: "no",
        selected: true,
      },
      {
        text: "Produit",
        value: "label",
        selected: true,
      },
      {
        text: "Type",
        value: "Type_Prd",
        selected: true,
      },
      {
        text: "Ratio",
        value: "ratio",
        slot: "cur",
        align: "end",
        selected: false,
        dec: 3,
      },
      {
        text: "Unité",
        value: "unit",
        selected: true,
      },
      {
        text: "Besoin",
        value: "qte",
        slot: "cur",
        align: "end",
        selected: true,
        dec: 2,
      },
      {
        text: "Stock",
        value: "stock_cmpt",
        slot: "cur",
        align: "end",
        selected: false,
        dec: 2,
      },
      {
        text: "Reservé",
        value: "stock_reserve",
        slot: "cur",
        align: "end",
        selected: false,
        dec: 2,
      },
      {
        text: "Disponible",
        value: "stock_dispo",
        slot: "cur",
        align: "end",
        selected: true,
        dec: 2,
        expire: true,
        cloture: true,
      },
      {
        text: "Qte Faisable",
        value: "fab_max",
        slot: "cur",
        align: "end",
        selected: true,
        dec: 2,
      },
      {
        text: "Arrivage",
        value: "cmd_fournisseur",
        slot: "cur",
        align: "end",
        selected: false,
        dec: 2,
      },
    ],
    tabs: "0",
    product: {},
    order: {},
    changed: false,
    loading: false,
    solde_list_load: false,
    cmds_list: [],
    items_list: [],
    items_headers: [],
    attributes: [],
    templates: [],
    besoins: [],
    lancer: false,
    receipt_i: false,
    liv: false,
    receipt_e: false,
    import: false,
    ret: false,
  }),

  computed: {
    arrivage() {
      return arrivage;
    },
    version() {
      return version;
    },
    attributs_list() {
      return this.attributes.filter((elm) => elm.type_attr == "colis");
    },

    qdelete_order() {
      return DELETE_ORDER;
    },
    Qselect() {
      return DETAILSCMDS;
    },
    Qselectall() {
      return ALLDETAILSCMDS;
    },

    orders_list: function () {
      return this.product.id > 0 ? this.product.orders : [];
    },
    avenants_list: function () {
      return this.product.id > 0 ? this.product.avenants : [];
    },
    receipts_list: function () {
      return this.product.id > 0 ? this.product.receipts : [];
    },
    rcpts_list: function () {
      return this.product.id > 0 ? this.product.rcpts : [];
    },
    imports_list: function () {
      return this.product.id > 0 ? this.product.arrivages : [];
    },
    livs_list: function () {
      return this.product.id > 0 ? this.product.livs : [];
    },
    rets_list: function () {
      return this.product.id > 0 ? this.product.retours : [];
    },
  },
  watch: {
    tiertype: {
      handler() {
        this.get_data();
        this.kp++;
      },
    },
    "$store.state.filtre": {
      handler() {
        this.filtre = this.$store.state.filtre;
        this.klist++;
      },
    },
  },
  created() {
    if (this.$route.params.filtre) {
      this.filtre = this.$route.params.filtre;
      this.klist++;
    }
  },

  mounted() {
    this.get_data();

    this.orders_headers[2].hiden =
      this.$store.state.structures.filter(
        (elm) => elm.type_id == 0 || elm.type_id == 1
      ).length == 1;
    this.orders_headers[6].hiden = this.version <= 2;
    this.headers[8].slot = this.tiertype > 2 ? "href3" : "cur";
    this.headers[11].slot = this.tiertype > 2 ? "href3" : "cur";
    this.headers[12].hiden =
      this.tiertype == 2 || this.tiertype == 4 || this.version < 2;
    this.headers[13].hiden =
      this.tiertype == 2 || this.tiertype == 4 || this.version < 2;
    this.headers[14].hiden =
      this.tiertype == 2 || this.tiertype == 4 || this.version < 2;
    this.headers[15].hiden = this.tiertype == 1 || this.tiertype == 3;
    this.headers[16].hiden =
      this.tiertype == 2 || this.tiertype == 4 || this.version < 2;

    //controler les actions de lancement et reception et livraisons
    this.lancer =
      (this.tiertype == 1 || this.tiertype == 3) &&
      (this.$store.state.auth.includes("02036") || this.$store.state.isadmin);
    this.valider =
      this.$store.state.auth.includes("03036") || this.$store.state.isadmin;
    this.solder =
      this.$store.state.auth.includes("04036") || this.$store.state.isadmin;
    this.solder_cmd =
      (this.tiertype == 1 && this.$store.state.auth.includes("04006")) ||
      (this.tiertype == 2 && this.$store.state.auth.includes("04005")) ||
      (this.tiertype == 3 && this.$store.state.auth.includes("04077")) ||
      (this.tiertype == 4 && this.$store.state.auth.includes("04076")) ||
      (this.tiertype == 5 && this.$store.state.auth.includes("04084")) ||
      (this.tiertype == 6 && this.$store.state.auth.includes("04085")) ||
      this.$store.state.isadmin;
    this.headers[25].hiden = !this.solder_cmd;
    this.headers[26].hiden = !this.solder_cmd;
    this.valider_cmd =
      (this.tiertype == 1 && this.$store.state.auth.includes("03006")) ||
      (this.tiertype == 2 && this.$store.state.auth.includes("03005")) ||
      (this.tiertype == 3 && this.$store.state.auth.includes("03077")) ||
      (this.tiertype == 4 && this.$store.state.auth.includes("03076")) ||
      this.$store.state.isadmin;
    this.receipt_i =
      ((this.tiertype == 1 || this.tiertype == 3) &&
        this.$store.state.auth.includes("02024")) ||
      this.$store.state.isadmin;
    this.receipt_e =
      ((this.tiertype == 2 || this.tiertype == 4) &&
        this.$store.state.auth.includes("02007")) ||
      this.$store.state.isadmin;
    this.import =
      ((this.tiertype == 2 || this.tiertype == 4) &&
        this.$store.state.auth.includes("02069")) ||
      this.$store.state.isadmin;
    this.liv =
      ((this.tiertype == 1 || this.tiertype == 3) &&
        this.$store.state.auth.includes("02009")) ||
      this.$store.state.isadmin;
    this.ret =
      ((this.tiertype == 1 || this.tiertype == 3) &&
        this.$store.state.auth.includes("02010")) ||
      ((this.tiertype == 2 || this.tiertype == 4) &&
        this.$store.state.auth.includes("02008")) ||
      this.$store.state.isadmin;
  },

  methods: {
    async maj(query, v) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          this.$store.dispatch("Changed", true);
          this.progress = false;
          r = data.data;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    get_motif(m) {
      this.motif = m;
    },
    async solder_list2(list) {
      let l = list.filter((elm) => elm.selected);
      if (l.length > 0) {
        this.is_motif = true;
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Etês-vous sûr de vouloir solder " +
              l.length +
              " lignes de commande?",
            { color: "orange darken-3" }
          )
        ) {
          await this.maj(SOLDER_LIGNES_CMD, {
            cmd_list: l.map((elm) => elm.id),
            motif: this.motif,
          });
        }
      }
    },
    async solder_ligne(item) {
      this.is_motif = !item.cloture;
      this.motif = null;
      let t = item.cloture ? "restorer" : "solder";
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Etês-vous sûr de vouloir " + t + " cette ligne de commande?",
          { color: "orange darken-3" }
        )
      ) {
        let v = {
          input: {
            id: item.id,
            canceled: item.cloture ? 0 : 1,
            motif: this.motif,
            write_uid: this.$store.state.me.id,
          },
        };

        await this.maj(UPDATE_PRODUIT, v);
        this.is_motif = false;
      }
    },
    update_list() {
      if (this.tabs == "0") {
        this.headers[9].selected = false;
        this.headers[10].selected = true;
        this.headers[11].value = "qte";
        this.headers[11].enum = "QTE";
        this.headers[15].value = "qte_import";
        this.headers[15].enum = "QTE_IMPORT";
        this.headers[16].value = "qte_rcpt";
        this.headers[16].enum = "QTE_RCPT";
        this.headers[18].value = "rest_import";
        this.headers[18].enum = "REST_IMPORT";
        this.headers[19].value = "qte_liv";
        this.headers[19].enum = "QTE_LIV";
        this.headers[21].value = "qte_ret";
        this.headers[21].enum = "QTE_RET";
        this.headers[22].value = "rest_liv";
        this.headers[22].enum = "REST_LIV";
      } else {
        this.headers[9].selected = true;
        this.headers[10].selected = false;
        this.headers[11].value = "val_cmd";
        this.headers[11].enum = "VAL_CMD";
        this.headers[15].value = "val_import";
        this.headers[15].enum = "VAL_IMPORT";
        this.headers[16].value = "val_rcpt";
        this.headers[16].enum = "VAL_RCPT";
        this.headers[18].value = "val_rest_import";
        this.headers[18].enum = "VAL_REST_IMPORT";
        this.headers[19].value = "val_liv";
        this.headers[19].enum = "VAL_LIV";
        this.headers[21].value = "val_ret";
        this.headers[21].enum = "VAL_RET";
        this.headers[22].value = "val_rest_liv";
        this.headers[22].enum = "VAL_REST_LIV";
      }

      this.klist++;
    },
    async solder_list() {
      let l = this.items_list.filter((elm) => elm.selected);
      if (l.length > 0) {
        this.is_motif = true;
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Etês-vous sûr de vouloir solder " +
              l.length +
              " lignes de commande?",
            { color: "orange darken-3" }
          )
        ) {
          await this.maj(SOLDER_LIGNES_CMD, {
            cmd_list: l.map((elm) => elm.id),
            motif: this.motif,
          });
        }
      }
      this.isListClose = true;
    },
    async show_solde_list() {
      let v = {
        where: {
          AND: [
            { column: "REST_LIV", operator: "LTE", value: 0 },
            { column: "DIFF_RCPT_LIV", value: 0 },
            { column: "CANCELED", value: 0 },
          ],
        },
      };
      this.solde_list_load = true;
      let r = await this.requette(ALLDETAILSCMDS, v);
      this.solde_list_load = false;
      if (r) {
        this.items_list = r.alldetailscmds;
        this.items_headers = this.solde_headers;
        this.form_title = "Lignes de commande à solder";
        this.w = "1600px";
        this.multiple = true;
        this.btn_save = true;
        this.add = false;
        this.del = false;
        this.kid = "id";
        this.isListClose = false;
        this.fl++;
      }
    },

    async faisabilite() {
      // if (this.product && this.product.produit_id > 0) {
      //   let v = {
      //     produit_id: this.product.produit_id,
      //   };
      //   let r = await this.requette(BESOIN_PRODUCT, v);
      //   if (r) {
      //     this.besoins = r.besoins;
      //   }
      // }
      this.items_list = [];
      if (this.besoins)
        for (let index = 0; index < this.besoins.length; index++) {
          const element = this.besoins[index];
          let elm = element;
          let qte = this.product.qte - this.product.qte_lanc;
          elm.qte = parseFloat((elm.ratio * qte).toFixed(2));
          if (this.arrivage) {
            elm.stock_dispo = elm.stock_dispo + elm.cmd_fournisseur;
            elm.fab_max = elm.stock_dispo / elm.ratio - elm.qte_prod;
          }
          elm.expire = elm.qte > elm.stock_dispo;
          elm.cloture = elm.qte <= elm.stock_dispo;

          elm.tol = "0";
          elm.id = element.produit_id;
          let tol = 0;
          elm.fab_max = elm.fab_max / (1 + tol / 100);
          this.items_list.push(elm);
        }

      this.items_headers = this.besoinsheaders;
      this.form_title =
        "Faisabilite de la commande " +
        this.product.label +
        " " +
        (this.product.qte - this.product.qte_lanc) +
        " " +
        this.product.unit;
      this.besoinsheaders[9].text = "Qte Faisaible (" + this.product.unit + ")";
      this.w = "1400px";
      this.kid = "produit_id";
      this.kfais++;
      this.isFaisabiliteClosed = false;
    },
    refresh() {
      this.kp++;
    },
    refresh_list() {
      this.openList(this.product);
    },
    sortBy(prop) {
      return function (a, b) {
        return a[prop] ? a[prop].localeCompare(b[prop]) : a[prop];
      };
    },
    async openList(item) {
      let v = {
        where: { column: "ID", value: item.id },
      };
      this.data_loading = true;
      let r = await this.requette(DETAILSCMD_FIND, v);
      this.data_loading = false;
      if (r) {
        this.product = r.detailscmds.data[0];
        this.product.col = item.col;
        this.kid = "id";
        this.subtitle =
          (this.tiertype <= 2 ? "Commande" : "Contrat") +
          " N°: " +
          this.product.nocmd;
        switch (item.col) {
          case "qte" || "pu":
            if (this.avenants_list)
              this.avenants_list.sort(this.sortBy("sign_date"));
            this.items_list = this.avenants_list.filter(
              (elm) =>
                elm.produit_id == this.product.produit_id &&
                elm.article_id == this.product.article_id
            );
            this.avenants_headers[4].text = item.unit_name;
            this.items_headers = this.avenants_headers;

            this.add = false;
            this.del = false;
            this.form_title = "Avenants";

            this.w = "1000px";
            break;
          case "qte_lanc":
            this.items_list = this.orders_list;
            this.orders_headers[1].slot = "href";
            this.items_headers = this.orders_headers;
            this.add =
              this.lancer &&
              this.product.tomanufacture &&
              this.product.qte > this.product.qte_lanc;
            this.del = this.lancer;
            this.form_title = "Cmds Int.: " + item.label;

            this.w = "1400px";
            break;
          case "qte_rcpt":
            if (this.rcpts_list.length > 0) this.items_list = this.rcpts_list;
            else this.items_list = this.receipts_list;
            this.rcpts_headers[5].text = item.unit_name;
            this.items_headers = this.rcpts_headers;
            this.add =
              this.receipt_i && this.product.qte_fab > this.product.qte_rcpt;
            this.del = false;
            this.form_title = "Rcpts Int.: " + item.label;
            this.w = this.product.linkcount < 2 ? "800px" : "1200px";

            break;
          case "qte_import":
            this.items_list = this.imports_list;
            this.imports_headers[2].text =
              "PU " + (item.devise ? "(" + item.devise + ")" : "");
            this.imports_headers[3].text = item.unit_name;
            this.imports_headers[4].text =
              "Montant " + (item.devise ? "(" + item.devise + ")" : "");

            this.items_headers = this.imports_headers;
            this.add = false;
            this.del = false;

            this.form_title = "Arrivages :" + item.label;
            this.w = "1200px";
            break;
          case "qte_liv":
            this.items_list = this.livs_list;
            this.livs_headers[4].text = item.unit_name;
            this.items_headers = this.livs_headers;
            if (this.tiertype == 1 || this.tiertype == 3)
              this.add =
                this.liv && this.product.qte_rcpt > this.product.qte_liv;
            if (this.tiertype == 2 || this.tiertype == 4)
              this.add =
                this.receipt_e && this.product.qte > this.product.qte_liv;
            this.del = false;

            this.form_title =
              (this.tiertype == 1 || this.tiertype == 3
                ? "Exps. ("
                : "Rcpts.: (") +
              item.unit +
              ") :" +
              item.label;
            this.w = "800px";
            break;
          case "qte_ret":
            this.items_list = this.rets_list;
            this.rets_headers[3].text = item.unit_name;
            this.items_headers = this.rets_headers;
            this.add = false;
            this.del = false;

            this.form_title = "Retour.: " + item.label;
            this.w = "800px";
            break;
        }
        // if (this.isListClose) {
        this.fl++;
        this.isListClose = false;
      }
    },
    closeList() {
      this.isListClose = true;
      this.multiple = false;
      this.btn_save = false;
      this.add = false;
      this.del = false;
    },
    closeOrderForm(item) {
      this.order = item;
      this.isOrderClosed = true;
    },
    init_list() {
      let colisheaders = [];
      this.colisheaders.forEach((element) => {
        colisheaders.push(element);
      });
      colisheaders[1].text = this.product.unit_name;
      colisheaders[1].rules = [
        (v) => !!v || this.product.unit_name + " obligatoire",
      ];
      colisheaders[1].dec = this.product.decimal;
      let colis_attributs = [];

      colis_attributs = this.product.ppattributs
        ? this.product.ppattributs.split(",")
        : [];
      for (let index = 0; index < colis_attributs.length; index++) {
        const element = colis_attributs[index];
        let i = this.attributs_list.findIndex((elm) => elm.id == element);
        if (i >= 0) {
          let ls = [];
          this.attributs_list[i].attributvalues.forEach((element) => {
            ls.push(element.name);
          });
          let elm = {
            text: this.attributs_list[i].caption
              ? this.attributs_list[i].caption
              : this.attributs_list[i].name,
            value: "ref" + index,
            selected: true,
            edit: true,
            type:
              this.attributs_list[i].attributvalues.length > 0
                ? "autocomplete"
                : "text",
            select_list: ls,
            show_list: ls,
            sm: 6,
            md: 6,
          };
          colisheaders.splice(index + 1, 0, elm);
        }
      }
      return colisheaders;
    },
    ItemChange(item) {
      if (this.product.col == "qte_lanc") this.order = item;
    },
    numberWithSpace: function (x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
    },
    async avc_cmd() {
      //verifier avance commande
      this.alert = false;
      let ok = true;
      if (this.product.avance > 0) {
        this.snackbar_text =
          "Verification de l'avance " + this.product.avance + "% ";
        this.snackbar_color = "primary";
        this.snackbar = true;

        let avc = 0;
        let avance = 0;
        v = {
          where: { column: "ID", value: this.product.cmd_id },
        };
        let r = await this.requette(ALLCMDS, v);
        if (r) {
          avc = ((r.allcmds[0].ttc * this.product.avance) / 100).toFixed(2);
        }

        let v = {
          where: {
            AND: [
              { column: "STATUT_ID", operator: "GTE", value: 17 },
              { column: "CMD_ID", value: this.product.cmd_id },
            ],
          },
        };

        r = await this.requette(ALLPAYE, v);
        if (r) {
          avance = r.allpaye.reduce((a, b) => a + (b["montant"] || 0), 0);
          if (avance < avc) ok = false;
        }
        if (ok == false) {
          this.message =
            "Attention l'avance perçue (" +
            this.numberWithSpace(avance.toFixed(2)) +
            ") est inferieur à la somme requise de " +
            this.product.avance +
            "% " +
            " (" +
            this.numberWithSpace(avc) +
            ") !!-verifier";
          this.type_alert = "error";
          this.alert = true;
        }
      }
      return ok;
    },
    async openForm(item) {
      if (this.product.col == "qte_lanc") {
        this.order = item;
        let ok = true;
        if (this.product.qte_lanc == 0) ok = await this.avc_cmd();

        if (ok) {
          this.isOrderClosed = false;
          this.korder++;
        }
      } else if (item.id > 0) {
        this.loading = true;
        let v = {
          mvm_detail_id:
            this.product.col == "qte_liv" || this.product.col == "qte_ret"
              ? item.mvm_detail_id
              : item.id,
        };
        let r = await this.requette(PRODUCT_LIST, v);
        this.loading = false;
        if (r) {
          this.items_list = r.product_list;
          this.items_headers = this.init_list();
          this.add = false;
          this.del = false;
          this.kid = "id";
          this.form_title = "Liste de colisage ";
          this.w = "1000px";
          this.fl++;
        }
      }
    },
    async get_data() {
      if (this.tiertype == 1 || this.tiertype == 3) {
        this.headers[12].hiden = false;
        this.orders_headers[6].hiden = false;

        this.headers[14].hiden = false;
        this.headers[15].hiden = true;
        this.headers[16].hiden = false;
        this.headers[17].text = "% Rcpt/Cmd";
        this.headers[17].value = "prc_rcpt";
        this.headers[18].hiden = true;
        this.headers[19].text = "Exp.";
        this.headers[20].text = "% Exp./Cmd";
        this.headers[22].hiden = true;
      } else {
        this.headers[13].hiden = true;
        this.headers[14].hiden = true;
        this.headers[15].hiden = true;
        this.headers[16].hiden = true;
        this.headers[17].text = "% Arr/Cmd";
        this.headers[17].value = "prc_import";
        this.headers[18].hiden = false;
        this.headers[19].text = "Rcpt.";
        this.headers[20].text = "% Rcpt./Cmd";
        this.headers[22].hiden = false;
      }

      let r = await this.requette(DATA);
      if (r) {
        this.templates = r.alltemplates;
        this.attributes = r.allattributes;
        this.units = r.units;
        this.boms = r.process_boms;
      }
      this.headers[4].text =
        this.tiertype == 1 || this.tiertype == 3 ? "Client" : "Fournisseur";
      this.klist++;
      this.order = {};
    },
    async requette(query, v) {
      let r;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
        })
        .catch((error) => {
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    ProductChange(item) {
      let c;
      if (this.product.col) c = this.product.col;
      this.selitem = item;
      this.product = item;
      this.product.cmd_detail_id = item.id;
      this.rcpts_headers[5].slot = this.product.ppattributs ? "href22" : "cur";
      this.livs_headers[4].slot = this.product.ppattributs ? "href22" : "cur";
      this.rets_headers[3].slot = this.product.ppattributs ? "href22" : "cur";
      this.rcpts_headers[6].hiden = this.product.ppattributs ? false : true;
      this.livs_headers[5].hiden = this.product.ppattributs ? false : true;
      this.livs_headers[4].dec = this.product.decimal;
      this.imports_headers[2].dec = this.product.decimal;
      this.rcpts_headers[5].dec = this.product.decimal;
      this.rcpts_headers[3].hiden = this.product.linkcount < 2;
      this.rcpts_headers[4].hiden = this.product.linkcount < 2;
      if (c) {
        this.product.col = c;
        //   this.openList(this.product);
      }
    },
  },
};
</script>
